<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        Update your agency's location.
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column mt-3">
        <v-row>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="profile.address"
              label="Address*"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <v-text-field
              v-model="profile.address2"
              label="Address 2"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
            />
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="profile.city"
              label="City*"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
            <v-select
              v-model="profile.state"
              label="State*"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
              :items="stateOptions"
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="profile.zip"
              label="Zip code*"
              dense
              filled
              background-color="white"
              @input="onChange"
              outlined
              :rules="[rules.required]"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      profile: {},
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (
        !this.profile.address ||
        !this.profile.city ||
        !this.profile.state ||
        !this.profile.zip
      ) {
        this.message = "Fill all required fields.";
        return;
      }
      this.loading = true;
      this.updateProfile({
        address: this.profile.address,
        address2: this.profile.address2,
        city: this.profile.city,
        state: this.profile.state,
        zip: this.profile.zip,
      })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile",
    }),
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    },
  },
  mounted() {
    this.profile = { ...this.myProfile };
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
