<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title
        style="display: flex; flex-direction: row; justify-content: space-between; font-family: 'Poppins-SemiBold'"
      >
        Edit Profile
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text style="font-family:'Poppins-Regular';">
        How your agency accomplishes its mission and vision: Its programs and
        activies...
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <div style="font-family:'Poppins-Medium'; color: #1e1e1e">
          What we do
        </div>
        <v-textarea
          rows="4"
          label="What we do"
          solo
          flat
          outlined
          class="mt-2"
          v-model="profile.what_we_do"
          style="font-family:'Poppins-Regular'; font-size: 12px;"
          :rules="[rules.required]"
        />
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn
          color="#b5e539"
          @click="onSave"
          class="white--text"
          width="150"
          :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      message: null,
      loading: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      profile: {},
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile",
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      if (!this.profile.what_we_do) {
        this.message = "Required field.";
        return;
      }
      this.loading = true;
      this.updateProfile({ what_we_do: this.profile.what_we_do })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.message = error.response.data.message;
          } else {
            this.message = error;
          }
        });
    },
  },
  computed: {
    ...mapState("auth", {
      myProfile: "profile",
    }),
  },
  watch: {
    myProfile(newValue) {
      this.profile = { ...newValue };
    },
  },
  mounted() {
    this.profile = { ...this.myProfile };
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
